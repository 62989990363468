/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { ApplicationPath } from '../models/ApplicationPath';
import { BackupPolicy } from '../models/BackupPolicy';
import { ControlViolations } from '../models/ControlViolations';
import { DatabaseProperties } from '../models/DatabaseProperties';
import { InventoryVault } from '../models/InventoryVault';
import { InventoryVolume } from '../models/InventoryVolume';

export class InventoryResource {
  /**
  * The private ID of the inventory item
  */
  'id': string;
  /**
  * The status of the inventory
  */
  'backupStatus': InventoryResourceBackupStatusEnum;
  /**
  * The resource ID of the inventory item in the cloud provider
  */
  'resourceId': string;
  /**
  * The name of the inventory item
  */
  'resourceName': string;
  /**
  * The type of the inventory item
  */
  'resourceType': InventoryResourceResourceTypeEnum;
  /**
  * The cloud provider of the account
  */
  'cloudProvider': InventoryResourceCloudProviderEnum;
  /**
  * The first time the inventory item was discovered
  */
  'dateDiscovered'?: Date;
  /**
  * The environment of the inventory item
  */
  'environment'?: InventoryResourceEnvironmentEnum;
  /**
  * The environment is overridden
  */
  'isEnvironmentOverridden'?: boolean;
  /**
  * The applications on this item are manually overridden
  */
  'areApplicationsOverridden'?: boolean;
  /**
  * The apps of the inventory item
  */
  'apps': Array<string>;
  /**
  * the list of applications that had application-specific data exported, and their paths on disk
  */
  'applicationsPaths'?: Array<ApplicationPath>;
  'dataClasses': Array<InventoryResourceDataClassesEnum>;
  'eonDataClassifications'?: Array<InventoryResourceEonDataClassificationsEnum>;
  /**
  * The data classifiers of the inventory item are overridden
  */
  'isDataClassesOverridden'?: boolean;
  /**
  * The tags of the inventory item
  */
  'tags': { [key: string]: string; };
  /**
  * The account ID of the inventory item
  */
  'accountId': string;
  /**
  * The source region of the inventory item
  */
  'sourceRegion': string;
  /**
  * The VPC of the inventory item
  */
  'vpc': string;
  /**
  * The subnets of the inventory item
  */
  'subnets': Array<string>;
  /**
  * The availability zones of the inventory item
  */
  'availabilityZones'?: Array<string>;
  /**
  * The number of general purpose snapshots of the inventory item
  */
  'genericSnapshotsCount': number;
  /**
  * The last backup timestamp
  */
  'lastBackup'?: Date;
  /**
  * The backup policy names of the inventory item
  */
  'backupPolicies': Array<BackupPolicy>;
  /**
  * The vault names of the inventory item
  */
  'vaults': Array<InventoryVault>;
  /**
  * The source storage size (in GiB) of the inventory item
  */
  'sourceStorageSize': number;
  /**
  * The source storage volumes of the inventory item
  */
  'sourceStorageVolumes': number;
  /**
  * The source
  */
  'sourceStorageFiles': number;
  /**
  * The backup storage size (in GB) of the inventory item
  */
  'backupStorageSize': number;
  /**
  * The backup storage cloud provider snapshots size of the inventory item
  */
  'backupStorageCloudSnapshotsSize'?: number;
  /**
  * The oldest snapshot timestamp
  */
  'oldestSnapshot'?: Date;
  /**
  * The newest snapshot timestamp
  */
  'newestSnapshot'?: Date;
  'volumes'?: Array<InventoryVolume>;
  'controlViolations'?: ControlViolations;
  'databaseProperties'?: DatabaseProperties | null;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "id",
      "baseName": "id",
      "type": "string",
      "format": ""
    },
    {
      "name": "backupStatus",
      "baseName": "backupStatus",
      "type": "InventoryResourceBackupStatusEnum",
      "format": ""
    },
    {
      "name": "resourceId",
      "baseName": "resourceId",
      "type": "string",
      "format": ""
    },
    {
      "name": "resourceName",
      "baseName": "resourceName",
      "type": "string",
      "format": ""
    },
    {
      "name": "resourceType",
      "baseName": "resourceType",
      "type": "InventoryResourceResourceTypeEnum",
      "format": ""
    },
    {
      "name": "cloudProvider",
      "baseName": "cloudProvider",
      "type": "InventoryResourceCloudProviderEnum",
      "format": ""
    },
    {
      "name": "dateDiscovered",
      "baseName": "dateDiscovered",
      "type": "Date",
      "format": "date-time"
    },
    {
      "name": "environment",
      "baseName": "environment",
      "type": "InventoryResourceEnvironmentEnum",
      "format": ""
    },
    {
      "name": "isEnvironmentOverridden",
      "baseName": "isEnvironmentOverridden",
      "type": "boolean",
      "format": ""
    },
    {
      "name": "areApplicationsOverridden",
      "baseName": "areApplicationsOverridden",
      "type": "boolean",
      "format": ""
    },
    {
      "name": "apps",
      "baseName": "apps",
      "type": "Array<string>",
      "format": ""
    },
    {
      "name": "applicationsPaths",
      "baseName": "applicationsPaths",
      "type": "Array<ApplicationPath>",
      "format": ""
    },
    {
      "name": "dataClasses",
      "baseName": "dataClasses",
      "type": "Array<InventoryResourceDataClassesEnum>",
      "format": ""
    },
    {
      "name": "eonDataClassifications",
      "baseName": "eonDataClassifications",
      "type": "Array<InventoryResourceEonDataClassificationsEnum>",
      "format": ""
    },
    {
      "name": "isDataClassesOverridden",
      "baseName": "isDataClassesOverridden",
      "type": "boolean",
      "format": ""
    },
    {
      "name": "tags",
      "baseName": "tags",
      "type": "{ [key: string]: string; }",
      "format": ""
    },
    {
      "name": "accountId",
      "baseName": "accountId",
      "type": "string",
      "format": ""
    },
    {
      "name": "sourceRegion",
      "baseName": "sourceRegion",
      "type": "string",
      "format": ""
    },
    {
      "name": "vpc",
      "baseName": "vpc",
      "type": "string",
      "format": ""
    },
    {
      "name": "subnets",
      "baseName": "subnets",
      "type": "Array<string>",
      "format": ""
    },
    {
      "name": "availabilityZones",
      "baseName": "availabilityZones",
      "type": "Array<string>",
      "format": ""
    },
    {
      "name": "genericSnapshotsCount",
      "baseName": "genericSnapshotsCount",
      "type": "number",
      "format": ""
    },
    {
      "name": "lastBackup",
      "baseName": "lastBackup",
      "type": "Date",
      "format": "date-time"
    },
    {
      "name": "backupPolicies",
      "baseName": "backupPolicies",
      "type": "Array<BackupPolicy>",
      "format": ""
    },
    {
      "name": "vaults",
      "baseName": "vaults",
      "type": "Array<InventoryVault>",
      "format": ""
    },
    {
      "name": "sourceStorageSize",
      "baseName": "sourceStorageSize",
      "type": "number",
      "format": ""
    },
    {
      "name": "sourceStorageVolumes",
      "baseName": "sourceStorageVolumes",
      "type": "number",
      "format": ""
    },
    {
      "name": "sourceStorageFiles",
      "baseName": "sourceStorageFiles",
      "type": "number",
      "format": ""
    },
    {
      "name": "backupStorageSize",
      "baseName": "backupStorageSize",
      "type": "number",
      "format": ""
    },
    {
      "name": "backupStorageCloudSnapshotsSize",
      "baseName": "backupStorageCloudSnapshotsSize",
      "type": "number",
      "format": ""
    },
    {
      "name": "oldestSnapshot",
      "baseName": "oldestSnapshot",
      "type": "Date",
      "format": "date-time"
    },
    {
      "name": "newestSnapshot",
      "baseName": "newestSnapshot",
      "type": "Date",
      "format": "date-time"
    },
    {
      "name": "volumes",
      "baseName": "volumes",
      "type": "Array<InventoryVolume>",
      "format": ""
    },
    {
      "name": "controlViolations",
      "baseName": "controlViolations",
      "type": "ControlViolations",
      "format": ""
    },
    {
      "name": "databaseProperties",
      "baseName": "databaseProperties",
      "type": "DatabaseProperties",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return InventoryResource.attributeTypeMap;
  }

  public constructor() {
  }
}


export enum InventoryResourceBackupStatusEnum {
  NotBackedUp = 'NOT_BACKED_UP',
  ViolationsDetected = 'VIOLATIONS_DETECTED',
  GenericBackups = 'GENERIC_BACKUPS',
  ExcludedFromBackup = 'EXCLUDED_FROM_BACKUP',
  AllViolationsMuted = 'ALL_VIOLATIONS_MUTED',
  Protected = 'PROTECTED',
  InitialClassification = 'INITIAL_CLASSIFICATION',
  Terminated = 'TERMINATED',
  Disconnected = 'DISCONNECTED'
}
export enum InventoryResourceResourceTypeEnum {
  Ebs = 'EBS',
  Ec2 = 'EC2',
  Efs = 'EFS',
  Rds = 'RDS',
  S3 = 'S3',
  Gce = 'GCE',
  VirtualMachine = 'Virtual Machine',
  CloudSql = 'CloudSQL',
  FileShare = 'File Share',
  AtlasCluster = 'Atlas Cluster',
  StorageAccount = 'Storage Account'
}
export enum InventoryResourceCloudProviderEnum {
  Aws = 'AWS',
  Azure = 'Azure',
  Gcp = 'GCP',
  MongoAtlas = 'MONGO_ATLAS'
}
export enum InventoryResourceEnvironmentEnum {
  Prod = 'PROD',
  ProdInternal = 'PROD_INTERNAL',
  Stage = 'STAGE'
}
export enum InventoryResourceDataClassesEnum {
  Unspecified = 'UNSPECIFIED',
  Pii = 'PII',
  Phi = 'PHI',
  Fi = 'FI'
}
export enum InventoryResourceEonDataClassificationsEnum {
  Unspecified = 'UNSPECIFIED',
  Pii = 'PII',
  Phi = 'PHI',
  Fi = 'FI'
}

