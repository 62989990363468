/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export class RestoreDatabaseInput {
  /**
  * The ID of the restore account (target)
  */
  'restoreAccountId': string;
  /**
  * The region of a new restore database
  */
  'restoreRegion'?: string;
  /**
  * Optional encryption key ID for a new restore database
  */
  'encryptionKeyId'?: string;
  /**
  * restore to snapshot or database
  */
  'restoreType': RestoreDatabaseInputRestoreTypeEnum;
  /**
  * Optional new name of the restored database
  */
  'restoredName'?: string;
  /**
  * Optional security group ID for the restored database (must match VPC of subnet Group)
  */
  'securityGroup'?: string;
  /**
  * Optional subnet group ID for the restored database (must match VPC of security Group)
  */
  'subnetGroup'?: string;
  /**
  * Optional tags to apply to the restored database
  */
  'tags'?: { [key: string]: string; };

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "restoreAccountId",
      "baseName": "restoreAccountId",
      "type": "string",
      "format": ""
    },
    {
      "name": "restoreRegion",
      "baseName": "restoreRegion",
      "type": "string",
      "format": ""
    },
    {
      "name": "encryptionKeyId",
      "baseName": "encryptionKeyId",
      "type": "string",
      "format": ""
    },
    {
      "name": "restoreType",
      "baseName": "restoreType",
      "type": "RestoreDatabaseInputRestoreTypeEnum",
      "format": ""
    },
    {
      "name": "restoredName",
      "baseName": "restoredName",
      "type": "string",
      "format": ""
    },
    {
      "name": "securityGroup",
      "baseName": "securityGroup",
      "type": "string",
      "format": ""
    },
    {
      "name": "subnetGroup",
      "baseName": "subnetGroup",
      "type": "string",
      "format": ""
    },
    {
      "name": "tags",
      "baseName": "tags",
      "type": "{ [key: string]: string; }",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return RestoreDatabaseInput.attributeTypeMap;
  }

  public constructor() {
  }
}


export enum RestoreDatabaseInputRestoreTypeEnum {
  Snapshot = 'RDS_DATABASE_TO_RDS_SNAPSHOT',
  Database = 'RDS_DATABASE_TO_RDS_DATABASE'
}

