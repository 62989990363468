/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { Condition } from '../models/Condition';

export class UpdateRoleInput {
  /**
  * The name of the role
  */
  'name'?: string;
  /**
  * The scopes of the role
  */
  'scopes'?: Array<string>;
  /**
  * Whether the role is a project role
  */
  'isProjectRole'?: boolean;
  /**
  * The data access rules of the role
  */
  'dataAccessRules'?: { [key: string]: Condition; };

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "name",
      "baseName": "name",
      "type": "string",
      "format": ""
    },
    {
      "name": "scopes",
      "baseName": "scopes",
      "type": "Array<string>",
      "format": ""
    },
    {
      "name": "isProjectRole",
      "baseName": "isProjectRole",
      "type": "boolean",
      "format": ""
    },
    {
      "name": "dataAccessRules",
      "baseName": "dataAccessRules",
      "type": "{ [key: string]: Condition; }",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return UpdateRoleInput.attributeTypeMap;
  }

  public constructor() {
  }
}

