/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export class RestoreVolumeInputVolumeSettings {
  /**
  * The volume type to use for the output volume
  */
  'type': string;
  /**
  * The size to use for the output volume
  */
  'size': number;
  /**
  * Optional IOPS override to use for the output volume
  */
  'iops'?: number;
  /**
  * Optional throughput override to use for the output volume
  */
  'throughput'?: number;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "type",
      "baseName": "type",
      "type": "string",
      "format": ""
    },
    {
      "name": "size",
      "baseName": "size",
      "type": "number",
      "format": ""
    },
    {
      "name": "iops",
      "baseName": "iops",
      "type": "number",
      "format": ""
    },
    {
      "name": "throughput",
      "baseName": "throughput",
      "type": "number",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return RestoreVolumeInputVolumeSettings.attributeTypeMap;
  }

  public constructor() {
  }
}

