import { BaseAPIRequestFactory, RequiredError } from './baseapi';
import type { Configuration } from '../configuration';
import { RequestContext, HttpMethod, ResponseContext, HttpInfo } from '../http/http';
import { ObjectSerializer } from '../models/ObjectSerializer';
import { ApiException } from './exception';
import { isCodeInRange } from '../util';
import type {SecurityAuthentication} from '../auth/auth';


import { FileExplorerResponse } from '../models/FileExplorerResponse';

/**
 * no description
 */
export class FileExplorerListPathApiRequestFactory extends BaseAPIRequestFactory {

  /**
   * Get all items in a given path
   * @param projectId The project ID
   * @param resourceId The resource ID
   * @param prefix The path prefix of the directory
   * @param pageSize The number of items to return per page
   * @param continuationToken The continuation token for pagination
   */
  public async fileExplorerListPath(projectId: string, resourceId: string, prefix?: string, pageSize?: number, continuationToken?: string, _options?: Configuration): Promise<RequestContext> {
    let _config;
    if (!_options) {
      _config = this.configuration;
    } else {
      _config = Object.assign({}, _options, this.configuration);
    }
    


    // verify required parameter 'projectId' is not null or undefined
    if (projectId === null || projectId === undefined) {
      throw new RequiredError("FileExplorerListPathApi", "fileExplorerListPath", "projectId");
    }


    // verify required parameter 'resourceId' is not null or undefined
    if (resourceId === null || resourceId === undefined) {
      throw new RequiredError("FileExplorerListPathApi", "fileExplorerListPath", "resourceId");
    }





    // Path Params
    const localVarPath = '/projects/{projectId}/explorer/{resourceId}'
      .replace('{' + 'projectId' + '}', encodeURIComponent(String(projectId)))
      .replace('{' + 'resourceId' + '}', encodeURIComponent(String(resourceId)));

    let signal = _config.timeout ? AbortSignal.timeout(_config.timeout) : undefined;
    // Make Request Context
    const requestContext = _config.baseServer!.makeRequestContext(localVarPath, HttpMethod.GET, signal);
    requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

    // Query Params
    if (prefix !== undefined) {
      requestContext.setQueryParam("prefix", ObjectSerializer.serialize(prefix, "string", ""));
    }

    // Query Params
    if (pageSize !== undefined) {
      requestContext.setQueryParam("pageSize", ObjectSerializer.serialize(pageSize, "number", ""));
    }

    // Query Params
    if (continuationToken !== undefined) {
      requestContext.setQueryParam("continuationToken", ObjectSerializer.serialize(continuationToken, "string", ""));
    }


    let authMethod: SecurityAuthentication | undefined;
    // Apply auth methods
    authMethod = _config.authMethods!["ApiKeyAuth"]
    if (authMethod?.applySecurityAuthentication) {
      await authMethod?.applySecurityAuthentication(requestContext);
    }

    return requestContext;
  }

}

export class FileExplorerListPathApiResponseProcessor {

  /**
   * Unwraps the actual response sent by the server from the response context and deserializes the response content
   * to the expected objects
   *
   * @params response Response returned by the server for a request to fileExplorerListPath
   * @throws ApiException if the response code was not in [200, 299]
   */
   public async fileExplorerListPathWithHttpInfo(response: ResponseContext): Promise<HttpInfo<FileExplorerResponse >> {
    if (isCodeInRange("200", response.httpStatusCode)) {
      const body: FileExplorerResponse = ObjectSerializer.deserialize(
        await response.body.json(),
        "FileExplorerResponse", ""
      ) as FileExplorerResponse;
      return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
    }
    if (isCodeInRange("1XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Informational", undefined, response.headers, response);
    }
    if (isCodeInRange("3XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Redirect", undefined, response.headers, response);
    }
    if (isCodeInRange("4XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Client Error", body, response.headers, response);
    }
    if (isCodeInRange("5XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Internal Server Error", body, response.headers, response);
    }

    // Work around for missing responses in specification, e.g. for petstore.yaml
    if (response.httpStatusCode > 200 || response.httpStatusCode > 299) {
      throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers, response);
    }
    const body: FileExplorerResponse = await response.body.json() as FileExplorerResponse;
    return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
  }

}
