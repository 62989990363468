'use client';

import { createContext, useState, type PropsWithChildren } from 'react';

import {
  createCombineCondition,
  isValueCondition,
  type Condition,
} from '@/types/advanceFilter';

export const NavigationQueryContext = createContext<{
  filter: Condition | undefined;
  setFilter: (filter: Condition | undefined) => void;
} | null>(null);

const normalizeFilter = (filter: Condition | undefined) => {
  if (!filter) {
    return undefined;
  }

  if (isValueCondition(filter)) {
    return createCombineCondition([filter]);
  }

  return filter.conditions.length === 0 ? undefined : filter;
};

export const NavigationQueryProvider = ({ children }: PropsWithChildren) => {
  const [filter, setFilter] = useState<Condition | undefined>();

  return (
    <NavigationQueryContext.Provider
      value={{
        filter,
        setFilter: (condition) => setFilter(normalizeFilter(condition)),
      }}
    >
      {children}
    </NavigationQueryContext.Provider>
  );
};
