/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export class UpdateResourceExclusionInput {
  /**
  * The resource IDs
  */
  'resourceIds'?: Array<string>;
  /**
  * Whether the resource should be excluded from backup
  */
  'excluded': boolean;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "resourceIds",
      "baseName": "resourceIds",
      "type": "Array<string>",
      "format": "uuid"
    },
    {
      "name": "excluded",
      "baseName": "excluded",
      "type": "boolean",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return UpdateResourceExclusionInput.attributeTypeMap;
  }

  public constructor() {
  }
}

