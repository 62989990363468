/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { SnapshotPropertiesVolumePropertiesInner } from '../models/SnapshotPropertiesVolumePropertiesInner';

/**
* Details regarding the resource at the current snapshot
*/
export class SnapshotProperties {
  'volumeProperties'?: Array<SnapshotPropertiesVolumePropertiesInner>;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "volumeProperties",
      "baseName": "volumeProperties",
      "type": "Array<SnapshotPropertiesVolumePropertiesInner>",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return SnapshotProperties.attributeTypeMap;
  }

  public constructor() {
  }
}

