/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { User } from '../models/User';

export class EonAccount {
  /**
  * The ID of the account
  */
  'id': string;
  /**
  * The name of the account
  */
  'name': string;
  /**
  * The domain of the account
  */
  'domain': string;
  /**
  * The status of the account
  */
  'status': string;
  /**
  * The user pool ID
  */
  'userPoolId': string;
  /**
  * The user pool client ID
  */
  'cognitoClientId': string;
  /**
  * The AWS Marketplace account ID
  */
  'awsSubscriptionId': string;
  'users'?: Array<User>;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "id",
      "baseName": "id",
      "type": "string",
      "format": ""
    },
    {
      "name": "name",
      "baseName": "name",
      "type": "string",
      "format": ""
    },
    {
      "name": "domain",
      "baseName": "domain",
      "type": "string",
      "format": ""
    },
    {
      "name": "status",
      "baseName": "status",
      "type": "string",
      "format": ""
    },
    {
      "name": "userPoolId",
      "baseName": "userPoolId",
      "type": "string",
      "format": ""
    },
    {
      "name": "cognitoClientId",
      "baseName": "cognitoClientId",
      "type": "string",
      "format": ""
    },
    {
      "name": "awsSubscriptionId",
      "baseName": "awsSubscriptionId",
      "type": "string",
      "format": ""
    },
    {
      "name": "users",
      "baseName": "users",
      "type": "Array<User>",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return EonAccount.attributeTypeMap;
  }

  public constructor() {
  }
}

