/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { Pagination } from '../models/Pagination';
import { ServiceAccount } from '../models/ServiceAccount';

export class ListServiceAccountsResponse {
  'serviceAccounts': Array<ServiceAccount>;
  'pagination': Pagination;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "serviceAccounts",
      "baseName": "serviceAccounts",
      "type": "Array<ServiceAccount>",
      "format": ""
    },
    {
      "name": "pagination",
      "baseName": "pagination",
      "type": "Pagination",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return ListServiceAccountsResponse.attributeTypeMap;
  }

  public constructor() {
  }
}

