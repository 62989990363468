import { useContext } from 'react';

import { NavigationQueryContext } from './navigationQueryContext';

export const useNavigationQuery = () => {
  const context = useContext(NavigationQueryContext);

  if (!context) {
    throw new Error(
      'useNavigationQuery must be used within a NavigationQueryProvider'
    );
  }

  return context;
};
