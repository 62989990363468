/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { SnapshotProperties } from '../models/SnapshotProperties';

export class Snapshot {
  /**
  * The ID of the snapshot
  */
  'id': string;
  /**
  * The ID of the project
  */
  'projectId'?: string;
  /**
  * The date the snapshot creation was initiated
  */
  'dateCreated': Date;
  /**
  * The date the snapshot was taken
  */
  'pointInTime'?: Date;
  /**
  * The ID of the vault the snapshot is stored in
  */
  'vaultId': string;
  /**
  * The ID of the resource the snapshot was taken from
  */
  'resourceId': string;
  /**
  * The backup retention in days
  */
  'backupRetention': number;
  /**
  * The archive retention in days
  */
  'archiveRetention'?: number;
  /**
  * Whether the snapshot is locked
  */
  'lock': boolean;
  /**
  * The status of the snapshot
  */
  'status': string;
  'properties'?: SnapshotProperties;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "id",
      "baseName": "id",
      "type": "string",
      "format": ""
    },
    {
      "name": "projectId",
      "baseName": "projectId",
      "type": "string",
      "format": ""
    },
    {
      "name": "dateCreated",
      "baseName": "dateCreated",
      "type": "Date",
      "format": "date-time"
    },
    {
      "name": "pointInTime",
      "baseName": "pointInTime",
      "type": "Date",
      "format": "date-time"
    },
    {
      "name": "vaultId",
      "baseName": "vaultId",
      "type": "string",
      "format": ""
    },
    {
      "name": "resourceId",
      "baseName": "resourceId",
      "type": "string",
      "format": ""
    },
    {
      "name": "backupRetention",
      "baseName": "backupRetention",
      "type": "number",
      "format": ""
    },
    {
      "name": "archiveRetention",
      "baseName": "archiveRetention",
      "type": "number",
      "format": ""
    },
    {
      "name": "lock",
      "baseName": "lock",
      "type": "boolean",
      "format": ""
    },
    {
      "name": "status",
      "baseName": "status",
      "type": "string",
      "format": ""
    },
    {
      "name": "properties",
      "baseName": "properties",
      "type": "SnapshotProperties",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return Snapshot.attributeTypeMap;
  }

  public constructor() {
  }
}

