/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export class DatabaseProperties {
  /**
  * The storageSize of the database
  */
  'storageSize': number;
  /**
  * The clusterId of the database
  */
  'clusterId': string;
  /**
  * The engine of the database
  */
  'engine': string;
  /**
  * The engineVersion of the database
  */
  'engineVersion': string;
  /**
  * The storageType of the database
  */
  'storageType': string;
  /**
  * The storageIops of the database
  */
  'storageIops': number;
  /**
  * The storageThroughput of the database
  */
  'storageThroughput': number;
  /**
  * The dbName of the database
  */
  'dbName': string;
  /**
  * The instanceClass of the database
  */
  'instanceClass': string;
  /**
  * The multiAZ of the database
  */
  'multiAZ': boolean;
  /**
  * The publiclyAccessible of the database
  */
  'publiclyAccessible': boolean;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "storageSize",
      "baseName": "storageSize",
      "type": "number",
      "format": ""
    },
    {
      "name": "clusterId",
      "baseName": "clusterId",
      "type": "string",
      "format": ""
    },
    {
      "name": "engine",
      "baseName": "engine",
      "type": "string",
      "format": ""
    },
    {
      "name": "engineVersion",
      "baseName": "engineVersion",
      "type": "string",
      "format": ""
    },
    {
      "name": "storageType",
      "baseName": "storageType",
      "type": "string",
      "format": ""
    },
    {
      "name": "storageIops",
      "baseName": "storageIops",
      "type": "number",
      "format": ""
    },
    {
      "name": "storageThroughput",
      "baseName": "storageThroughput",
      "type": "number",
      "format": ""
    },
    {
      "name": "dbName",
      "baseName": "dbName",
      "type": "string",
      "format": ""
    },
    {
      "name": "instanceClass",
      "baseName": "instanceClass",
      "type": "string",
      "format": ""
    },
    {
      "name": "multiAZ",
      "baseName": "multiAZ",
      "type": "boolean",
      "format": ""
    },
    {
      "name": "publiclyAccessible",
      "baseName": "publiclyAccessible",
      "type": "boolean",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return DatabaseProperties.attributeTypeMap;
  }

  public constructor() {
  }
}

