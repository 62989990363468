import {
  GetAzureConsentUrlApi,
  GetAzureOnboardingStatusApi,
  GetAzureSubscriptionsApi,
  OnboardAzureSubscriptionsApi,
  type AzureSubscription,
  type Configuration,
} from '@repo/api-gw-sdk';

import type { HttpClient } from './httpClient';

export const azure = (
  httpClient: HttpClient,
  config: Configuration,
  currentProjectId: string
) => {
  const consentUrlApi = new GetAzureConsentUrlApi(config);
  const listSubscriptionsApi = new GetAzureSubscriptionsApi(config);
  const onboardSubscriptionsApi = new OnboardAzureSubscriptionsApi(config);
  const onboardingStatusApi = new GetAzureOnboardingStatusApi(config);

  return {
    getConsentUrl: (requestId: string) =>
      consentUrlApi.getAzureConsentUrl(currentProjectId, requestId),
    listSubscriptions: (requestId: string) =>
      listSubscriptionsApi.getAzureSubscriptions(currentProjectId, requestId),
    onboardSubscriptions: (
      requestId: string,
      subscriptions: AzureSubscription[]
    ) =>
      onboardSubscriptionsApi.onboardAzureSubscriptions(
        currentProjectId,
        requestId,
        {
          subscriptions,
        }
      ),
    getOnboardingStatus: (requestId: string) =>
      onboardingStatusApi.getAzureOnboardingStatus(currentProjectId, requestId),
  };
};
