/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export class RestoreBucketInput {
  /**
  * The ID of the restore account (target)
  */
  'restoreAccountId': string;
  /**
  * The name of a new restore bucket
  */
  'restoreBucketName': string;
  /**
  * The prefix to use for the restored files in the bucket
  */
  'restoreBucketPrefix'?: string;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "restoreAccountId",
      "baseName": "restoreAccountId",
      "type": "string",
      "format": ""
    },
    {
      "name": "restoreBucketName",
      "baseName": "restoreBucketName",
      "type": "string",
      "format": ""
    },
    {
      "name": "restoreBucketPrefix",
      "baseName": "restoreBucketPrefix",
      "type": "string",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return RestoreBucketInput.attributeTypeMap;
  }

  public constructor() {
  }
}

