/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export class ConvertVolumeInput {
  /**
  * The ID of the eon snapshot
  */
  'snapshotId': string;
  /**
  * The ID of the restore account (target)
  */
  'restoreAccountId': string;
  /**
  * The target region
  */
  'regionName': string;
  /**
  * Optional description of the output snapshot
  */
  'description'?: string;
  /**
  * Optional tags to apply to the output snapshot
  */
  'tags': { [key: string]: string; };
  /**
  * Optional encryption key ID to use for the output snapshot
  */
  'snapshotEncryptionKeyId'?: string;
  /**
  * Optional encryption key ID to use for the restore process
  */
  'encryptionKeyId'?: string;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "snapshotId",
      "baseName": "snapshotId",
      "type": "string",
      "format": ""
    },
    {
      "name": "restoreAccountId",
      "baseName": "restoreAccountId",
      "type": "string",
      "format": ""
    },
    {
      "name": "regionName",
      "baseName": "regionName",
      "type": "string",
      "format": ""
    },
    {
      "name": "description",
      "baseName": "description",
      "type": "string",
      "format": ""
    },
    {
      "name": "tags",
      "baseName": "tags",
      "type": "{ [key: string]: string; }",
      "format": ""
    },
    {
      "name": "snapshotEncryptionKeyId",
      "baseName": "snapshotEncryptionKeyId",
      "type": "string",
      "format": ""
    },
    {
      "name": "encryptionKeyId",
      "baseName": "encryptionKeyId",
      "type": "string",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return ConvertVolumeInput.attributeTypeMap;
  }

  public constructor() {
  }
}

