import {
  ConvertVolumeApi,
  type Configuration,
  type ConvertVolumeInput,
} from '@repo/api-gw-sdk';

import type { HttpClient } from './httpClient';

export const convert = (
  httpClient: HttpClient,
  config: Configuration,
  currentProjectId: string
) => {
  const convertVolumeApi = new ConvertVolumeApi(config);

  return {
    volume: (
      resourceId: string,
      volumeId: string,
      payload: ConvertVolumeInput
    ) =>
      convertVolumeApi.convertVolume(
        currentProjectId,
        resourceId,
        volumeId,
        payload
      ),
  };
};
