import type {
  UpdateServiceAccountRequest,
  Configuration,
  CreateServiceAccountRequest,
} from '@repo/api-gw-sdk';
import {
  DeleteServiceAccountApi,
  ListServiceAccountsApi,
  UpdateServiceAccountApi,
  CreateServiceAccountApi,
} from '@repo/api-gw-sdk';

import type { HttpClient } from './httpClient';

const baseUrl = '/service-accounts';

export const serviceAccounts = (
  httpClient: HttpClient,
  config: Configuration
) => {
  const listServiceAccountsApi = new ListServiceAccountsApi(config);
  const createServiceAccountApi = new CreateServiceAccountApi(config);
  const deleteServiceAccountApi = new DeleteServiceAccountApi(config);
  const updateServiceAccountApi = new UpdateServiceAccountApi(config);

  return {
    list: () =>
      httpClient.execute([baseUrl], () =>
        listServiceAccountsApi.listServiceAccounts()
      ),
    create: (serviceAccount: CreateServiceAccountRequest) =>
      createServiceAccountApi.createServiceAccount(serviceAccount),
    update: (id: string, serviceAccount: UpdateServiceAccountRequest) =>
      updateServiceAccountApi.updateServiceAccount(id, serviceAccount),
    delete: (id: string) => deleteServiceAccountApi.deleteServiceAccount(id),
  };
};
