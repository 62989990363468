/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { DailyStorageSummary } from '../models/DailyStorageSummary';

export class GetDailyStorageSummaries {
  'data': Array<DailyStorageSummary>;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "data",
      "baseName": "data",
      "type": "Array<DailyStorageSummary>",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return GetDailyStorageSummaries.attributeTypeMap;
  }

  public constructor() {
  }
}

