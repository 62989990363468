import { ListAuditLogsApi, type Configuration } from '@repo/api-gw-sdk';
import type { ColumnSort, PaginationState } from '@tanstack/react-table';

import { toJsonText } from '@/components/queryBuilder/QueryLanguageConverter';
import type { Condition } from '@/types/advanceFilter';

import type { HttpClient } from './httpClient';

const baseUrl = '/auditlogs';

export const auditLogs = (httpClient: HttpClient, config: Configuration) => {
  const listAuditLogsApi = new ListAuditLogsApi(config);

  return {
    list: (
      pagination: PaginationState,
      sorting: ColumnSort,
      columnFilters: Condition | undefined
    ) => {
      const filter = columnFilters ? toJsonText(columnFilters) : undefined;
      return httpClient.execute([baseUrl, pagination, sorting, filter], () =>
        listAuditLogsApi.listAuditLogs(
          pagination.pageIndex,
          pagination.pageSize,
          `${sorting.id}:${sorting.desc ? 'desc' : 'asc'}`,
          filter
        )
      );
    },
  };
};
