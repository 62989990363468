import { RefreshApi } from '@repo/api-gw-sdk';
import { usePathname, useSearchParams } from 'next/navigation';
import { useEffect, useMemo, useRef, useState } from 'react';

import { toJsonText } from '@/components/queryBuilder/QueryLanguageConverter';
import { createConfig } from '@/contexts/api-config';
import { useEnvironment } from '@/contexts/useEnvironment';
import { useNavigationQuery } from '@/contexts/useNavigationQuery';
import { useUser } from '@/contexts/useUser';
import { getParsedJwt } from '@/libs/auth';
import { parseJson } from '@/utils/string';

import { dalFactory } from './dalFactory';
import { createHttpClient } from './httpClient';

export function useDAL(projectId?: string) {
  const {
    clientApiBaseUrl: baseUrl,
    authToken,
    exposedTestingGlobals,
    updateAuthToken,
    isDev,
  } = useEnvironment();

  const { currentProjectId } = useUser(true);
  const proj = projectId ?? currentProjectId;

  const dal = useMemo(() => {
    const httpClient = createHttpClient(baseUrl, authToken);
    const configuration = createConfig(baseUrl, authToken, [
      {
        pre: async (context) => {
          if (!context.getUrl().endsWith('/refresh')) {
            const jwt = getParsedJwt(authToken);
            const exp = jwt?.exp;
            if (typeof exp === 'number') {
              const now = Math.floor(Date.now() / 1000);
              // if token expires in 2 minutes, refresh it
              if (exp < now + 120) {
                const response = await new RefreshApi(configuration).refresh();
                if (response.message) {
                  updateAuthToken(response.message);
                }

                return context;
              }
            }
          }

          return context;
        },
        post: async (context) => {
          if (context.httpStatusCode === 401) {
            // The user is not authenticated, delete the next jwt and redirect to login
            location.href = '/logout';
          }
          return context;
        },
      },
    ]);

    if (exposedTestingGlobals && typeof window !== 'undefined') {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).populateTestData = async () => {
        await httpClient.put(`/projects/${proj}/populate?version=1`, '{}');
        await httpClient.put(
          `/projects/${proj}/inventory/populate?version=1`,
          '{}'
        );
        await httpClient.put(
          `/projects/${proj}/search/populate?version=1`,
          '{}'
        );
      };

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).cleanPopulatedTestData = async () => {
        await httpClient.delete(
          `/projects/${currentProjectId}/search/populate?version=1`
        );
      };
    }

    const dal = dalFactory(httpClient, configuration, proj!); // TODO: Take care of case when currentProjectId is null
    if (isDev && typeof window !== 'undefined') {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).dal = dal;
    }
    return dal;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authToken, baseUrl, proj]);

  return dal;
}

const defaultValueExtractor = (
  value: string | undefined,
  defaultValue: unknown
) => {
  switch (typeof defaultValue) {
    case 'string': {
      return value ? value : defaultValue;
    }
    case 'number': {
      const parsed = parseInt(value || '', 10);
      return isNaN(parsed) ? defaultValue : parsed;
    }
    case 'boolean': {
      return !value ? defaultValue : value === 'true';
    }
  }

  throw new Error('Invalid default value type');
};

const mergeDefaultValuesWithSearchParams = (
  searchParams: URLSearchParams,
  defaultValues: Record<string, string | number | boolean>
) =>
  Object.entries(defaultValues).reduce((agg, [key, value]) => {
    agg[key] = String(defaultValueExtractor(agg[key], value));
    return agg;
  }, Object.fromEntries(searchParams.entries()));

const historyEvents = ['popstate', 'pushstate', 'replacestate'];

export function useShallowNavigation(
  defaultValues: Record<string, string | number | boolean>
) {
  const searchParams = useSearchParams();
  const { filter, setFilter } = useNavigationQuery();
  const pathname = usePathname();
  const [params, setParams] = useState(
    mergeDefaultValuesWithSearchParams(searchParams, defaultValues)
  );
  const [initialized, setInitialized] = useState(false);
  const firstRender = useRef(true);

  const onBrowserHistoryChange = () => {
    const newParams = mergeDefaultValuesWithSearchParams(
      new URLSearchParams(window.location.search),
      defaultValues
    );
    setFilter(parseJson(newParams.query));
    setParams(newParams);
  };

  useEffect(function () {
    // sync the filter context with the url after defaults were set
    setFilter(parseJson(params.query));
    setInitialized(true);

    const abortController = new AbortController();
    historyEvents.forEach((event) =>
      window.addEventListener(event, onBrowserHistoryChange, {
        signal: abortController.signal,
      })
    );

    return () => abortController.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // when the params change, update the url
  useEffect(() => {
    const newUrl = Object.keys(params).length
      ? `${pathname}?${new URLSearchParams(params).toString()}`
      : pathname;

    if (newUrl !== window.location.pathname + window.location.search) {
      if (firstRender.current) {
        // when setting the history for the first time, we are replacing the current url
        // so we won't have another back history due to adding the default params.
        window.history.replaceState(
          { ...window.history.state, as: newUrl, url: newUrl },
          '',
          newUrl
        );
      } else {
        window.history.pushState(
          { ...window.history.state, as: newUrl, url: newUrl },
          '',
          newUrl
        );
      }
    }
    firstRender.current = false;
  }, [pathname, params]);

  // when filter context changes, update the params
  useEffect(() => {
    // if the filter context wasn't initialized yet with url, don't do anything
    if (!initialized) {
      return;
    }

    setParams((state) => {
      const query = filter && toJsonText(filter);
      const { query: oldQuery, ...rest } = state;
      if (query === oldQuery) {
        return state;
      }
      return query
        ? {
            ...rest,
            query,
          }
        : rest;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  return [params, setParams] as const;
}
