/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { Condition } from '../models/Condition';
import { ControlRules } from '../models/ControlRules';

export class Control {
  /**
  * The id of the control
  */
  'id': string;
  /**
  * The name of the control
  */
  'name': string;
  /**
  * The severity of the control
  */
  'severity': ControlSeverityEnum;
  'condition': Condition;
  'rules': ControlRules;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "id",
      "baseName": "id",
      "type": "string",
      "format": ""
    },
    {
      "name": "name",
      "baseName": "name",
      "type": "string",
      "format": ""
    },
    {
      "name": "severity",
      "baseName": "severity",
      "type": "ControlSeverityEnum",
      "format": ""
    },
    {
      "name": "condition",
      "baseName": "condition",
      "type": "Condition",
      "format": ""
    },
    {
      "name": "rules",
      "baseName": "rules",
      "type": "ControlRules",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return Control.attributeTypeMap;
  }

  public constructor() {
  }
}


export enum ControlSeverityEnum {
  High = 'HIGH',
  Medium = 'MEDIUM',
  Low = 'LOW'
}

