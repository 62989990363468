import type {
  RestoreVolumeInput,
  Configuration,
  RestoreFilesInput,
  RestoreDatabaseInput,
  RestoreBucketInput,
  RestoreAtlasClusterInput,
} from '@repo/api-gw-sdk';
import {
  ListS3BucketsApi,
  ListAvailabilityZonesApi,
  ListEncryptionKeysApi,
  ListRestoreRegionsApi,
  RestoreVolumeApi,
  RestoreFilesApi,
  RestoreDatabaseApi,
  ListAwsSecurityGroupsApi,
  ListAwsRdsSubnetGroupsApi,
  RestoreBucketApi,
  ListAtlasProjectsApi,
  RestoreAtlasClusterApi,
} from '@repo/api-gw-sdk';

import type { HttpClient } from './httpClient';

const baseUrl = '/restore';

export const restore = (
  httpClient: HttpClient,
  config: Configuration,
  currentProjectId: string
) => {
  const restoreFilesApi = new RestoreFilesApi(config);
  const restoreBucketApi = new RestoreBucketApi(config);
  const listS3BucketsApi = new ListS3BucketsApi(config);
  const restoreVolumeApi = new RestoreVolumeApi(config);
  const restoreDatabaseApi = new RestoreDatabaseApi(config);
  const listEncryptionKeysApi = new ListEncryptionKeysApi(config);
  const listAwsSecurityGroupsApi = new ListAwsSecurityGroupsApi(config);
  const listRestoreRegionsApi = new ListRestoreRegionsApi(config);
  const listAvailabilityZonesApi = new ListAvailabilityZonesApi(config);
  const listAtlasProjectsApi = new ListAtlasProjectsApi(config);
  const restoreAtlasClusterApi = new RestoreAtlasClusterApi(config);
  const listAwsRdsSubnetGroupsApi = new ListAwsRdsSubnetGroupsApi(config);

  return {
    volume: (
      resourceId: string,
      volumeId: string,
      payload: RestoreVolumeInput
    ) =>
      restoreVolumeApi.restoreVolume(
        currentProjectId,
        resourceId,
        volumeId,
        payload
      ),
    files: (
      resourceId: string,
      snapshotId: string,
      restoreFilesInput: RestoreFilesInput
    ) =>
      restoreFilesApi.restoreFiles(
        currentProjectId,
        resourceId,
        snapshotId,
        restoreFilesInput
      ),
    database: (
      id: string,
      snapshotId: string,
      restoreDatabaseInput: RestoreDatabaseInput
    ) =>
      restoreDatabaseApi.restoreDatabase(
        currentProjectId,
        id,
        snapshotId,
        restoreDatabaseInput
      ),
    encryptionKeys: {
      list: (cloudAccountId: string, regionName: string) =>
        httpClient.execute(
          [
            baseUrl,
            cloudAccountId,
            '/encryptionKeys',
            regionName,
            currentProjectId,
          ],
          () =>
            listEncryptionKeysApi.listEncryptionKeys(
              cloudAccountId,
              regionName,
              currentProjectId
            )
        ),
    },

    regions: {
      list: (cloudAccountId: string) =>
        httpClient.execute(
          [baseUrl, cloudAccountId, '/regions', currentProjectId],
          () =>
            listRestoreRegionsApi.listRestoreRegions(
              cloudAccountId,
              currentProjectId
            )
        ),
    },
    availabilityZones: {
      list: (cloudAccountId: string, region: string) =>
        httpClient.execute(
          [
            baseUrl,
            cloudAccountId,
            '/regions',
            region,
            '/availabilityZones',
            currentProjectId,
          ],
          () =>
            listAvailabilityZonesApi.listAvailabilityZones(
              cloudAccountId,
              region,
              currentProjectId
            )
        ),
    },
    s3Buckets: {
      list: (accountId: string) =>
        httpClient.execute([baseUrl, '/buckets', currentProjectId], () =>
          listS3BucketsApi.listS3Buckets(accountId, currentProjectId)
        ),
      restore: (
        resourceId: string,
        snapshotId: string,
        restoreBucketInput: RestoreBucketInput
      ) =>
        restoreBucketApi.restoreBucket(
          currentProjectId,
          resourceId,
          snapshotId,
          restoreBucketInput
        ),
    },
    securityGroups: {
      list: (cloudAccountId: string, region: string, vpc: string) =>
        httpClient.execute(
          [
            baseUrl,
            cloudAccountId,
            '/regions',
            region,
            '/aws-security-groups',
            currentProjectId,
          ],
          () =>
            listAwsSecurityGroupsApi.listAwsSecurityGroups(
              cloudAccountId,
              region,
              currentProjectId,
              vpc
            )
        ),
    },
    rdsSubnetGroup: {
      list: (cloudAccountId: string, region: string) =>
        httpClient.execute(
          [
            baseUrl,
            cloudAccountId,
            '/regions',
            region,
            '/aws-rds-subnet-groups',
            currentProjectId,
          ],
          () =>
            listAwsRdsSubnetGroupsApi.listAwsRdsSubnetGroups(
              cloudAccountId,
              region,
              currentProjectId
            )
        ),
    },
    atlasProjects: {
      list: (accountId: string) =>
        httpClient.execute([baseUrl, '/atlasProjects', currentProjectId], () =>
          listAtlasProjectsApi.listAtlasProjects(accountId, currentProjectId)
        ),
      restore: (
        resourceId: string,
        snapshotId: string,
        restoreAtlasClusterInput: RestoreAtlasClusterInput
      ) =>
        restoreAtlasClusterApi.restoreAtlasCluster(
          currentProjectId,
          resourceId,
          snapshotId,
          restoreAtlasClusterInput
        ),
    },
  };
};
