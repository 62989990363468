/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export class Pagination {
  'recordsCount': number;
  'queryExecutionId'?: string;
  'continuationToken'?: string;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "recordsCount",
      "baseName": "recordsCount",
      "type": "number",
      "format": ""
    },
    {
      "name": "queryExecutionId",
      "baseName": "queryExecutionId",
      "type": "string",
      "format": ""
    },
    {
      "name": "continuationToken",
      "baseName": "continuationToken",
      "type": "string",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return Pagination.attributeTypeMap;
  }

  public constructor() {
  }
}

