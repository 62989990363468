/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export class DataClassificationsUpdateRequest {
  'resourceIds'?: Array<string>;
  'dataClassifications'?: Array<DataClassificationsUpdateRequestDataClassificationsEnum>;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "resourceIds",
      "baseName": "resourceIds",
      "type": "Array<string>",
      "format": ""
    },
    {
      "name": "dataClassifications",
      "baseName": "dataClassifications",
      "type": "Array<DataClassificationsUpdateRequestDataClassificationsEnum>",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return DataClassificationsUpdateRequest.attributeTypeMap;
  }

  public constructor() {
  }
}


export enum DataClassificationsUpdateRequestDataClassificationsEnum {
  Unspecified = 'UNSPECIFIED',
  Pii = 'PII',
  Phi = 'PHI',
  Fi = 'FI'
}

