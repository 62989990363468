/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export class FilePath {
  /**
  * The path
  */
  'path': string;
  /**
  * Whether the path is a directory
  */
  'isDirectory': boolean;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "path",
      "baseName": "path",
      "type": "string",
      "format": ""
    },
    {
      "name": "isDirectory",
      "baseName": "isDirectory",
      "type": "boolean",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return FilePath.attributeTypeMap;
  }

  public constructor() {
  }
}

