/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { AuditLogEntitiesInner } from '../models/AuditLogEntitiesInner';

export class AuditLog {
  /**
  * The ID of the audit log
  */
  'id'?: string;
  /**
  * The user ID
  */
  'userId'?: string;
  /**
  * The user name
  */
  'userName'?: string;
  /**
  * The ID of the request
  */
  'requestId'?: string;
  /**
  * The time of the request
  */
  'requestTime'?: Date;
  /**
  * The http verb of the request
  */
  'verb'?: string;
  /**
  * The path of the request
  */
  'path'?: string;
  /**
  * The route path the request was sent to, with path parameters replaced with a placeholder. For example:    - Requests sent to `/users/123` are normalized `/users/:id`.   - Requests sent to `/users/123/query` are normalized `/users/:id/query`.
  */
  'normalizedPath'?: string;
  /**
  * The status code in the response to the audited request.
  */
  'statusCode'?: number;
  /**
  * The operation ID of the request
  */
  'operationId'?: string;
  /**
  * The action category of the request
  */
  'action'?: string;
  'entities'?: Array<AuditLogEntitiesInner>;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "id",
      "baseName": "id",
      "type": "string",
      "format": ""
    },
    {
      "name": "userId",
      "baseName": "userId",
      "type": "string",
      "format": ""
    },
    {
      "name": "userName",
      "baseName": "userName",
      "type": "string",
      "format": ""
    },
    {
      "name": "requestId",
      "baseName": "requestId",
      "type": "string",
      "format": ""
    },
    {
      "name": "requestTime",
      "baseName": "requestTime",
      "type": "Date",
      "format": "date-time"
    },
    {
      "name": "verb",
      "baseName": "verb",
      "type": "string",
      "format": ""
    },
    {
      "name": "path",
      "baseName": "path",
      "type": "string",
      "format": ""
    },
    {
      "name": "normalizedPath",
      "baseName": "normalizedPath",
      "type": "string",
      "format": ""
    },
    {
      "name": "statusCode",
      "baseName": "statusCode",
      "type": "number",
      "format": ""
    },
    {
      "name": "operationId",
      "baseName": "operationId",
      "type": "string",
      "format": ""
    },
    {
      "name": "action",
      "baseName": "action",
      "type": "string",
      "format": ""
    },
    {
      "name": "entities",
      "baseName": "entities",
      "type": "Array<AuditLogEntitiesInner>",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return AuditLog.attributeTypeMap;
  }

  public constructor() {
  }
}

