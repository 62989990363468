import {
  FileexplorerListdirectoriesApi,
  FileexplorerListfilesApi,
  FileExplorerListPathApi,
  ListPathSnapshotsApi,
  type Configuration,
} from '@repo/api-gw-sdk';
import type { PaginationState } from '@tanstack/react-table';

import { setEndOfDay, setStartOfDay } from '@/utils/dateTime';

import type { HttpClient } from './httpClient';

export const explorer = (
  httpClient: HttpClient,
  config: Configuration,
  currentProjectId: string
) => {
  const filesClient = new FileexplorerListfilesApi(config);
  const dirsClient = new FileexplorerListdirectoriesApi(config);
  const snapshotsClient = new ListPathSnapshotsApi(config);
  const listPathClient = new FileExplorerListPathApi(config);

  return {
    getFiles: (
      resourceId: string,
      prefix: string,
      depth: number,
      pagination: PaginationState
    ) =>
      httpClient.execute(
        [
          'explorer_files',
          resourceId,
          prefix,
          depth,
          pagination,
          currentProjectId,
        ],
        () =>
          filesClient.fileexplorerListfiles(
            currentProjectId,
            resourceId,
            depth,
            prefix,
            pagination.pageIndex,
            pagination.pageSize
          )
      ),
    getDirectories: (
      resourceId: string,
      prefix: string,
      depth: number,
      pagination: PaginationState
    ) =>
      httpClient.execute(
        [
          'explorer_dirs',
          resourceId,
          prefix,
          depth,
          pagination,
          currentProjectId,
        ],
        () =>
          dirsClient.fileexplorerListdirectories(
            currentProjectId,
            resourceId,
            depth,
            prefix,
            pagination.pageIndex,
            pagination.pageSize
          )
      ),
    listPath: (
      resourceId: string,
      prefix: string,
      pageSize: number,
      continuationToken?: string
    ) => {
      return httpClient.execute(
        [
          'listPath',
          resourceId,
          prefix,
          pageSize,
          continuationToken,
          currentProjectId,
        ],
        () =>
          listPathClient.fileExplorerListPath(
            currentProjectId,
            resourceId,
            prefix,
            pageSize,
            continuationToken
          )
      );
    },
    listPathSnapshots: (
      resourceId: string,
      path: string,
      start: Date,
      end: Date,
      pageIndex: number,
      pageSize: number
    ) => {
      const startWithoutTime = setStartOfDay(start);
      const endWithoutTime = setEndOfDay(end);
      return httpClient.execute(
        [
          'listPathSnapshots',
          resourceId,
          startWithoutTime.toISOString(),
          endWithoutTime.toISOString(),
          path,
          pageIndex,
          pageSize,
          currentProjectId,
        ],
        () =>
          snapshotsClient.listPathSnapshots(
            resourceId,
            startWithoutTime,
            endWithoutTime,
            pageIndex,
            pageSize,
            currentProjectId,
            {
              path,
            }
          )
      );
    },
  };
};
